.layout-wrapper {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  min-height: 100vh;
  height: 100%;

  .content-wrapper {
    transition: all 0.3s ease-in-out;
    margin-left: 200px;
  }

  .content-wrapper.small-sidebar {
    transition: all 0.3s ease-in-out;
    margin-left: 80px;
  }

  .content-wrapper.hidden-sidebar {
    transition: all 0.3s ease-in-out;
    margin-left: 0;
  }

  .content-inner {
    position: relative;
    padding: 1.75rem;
    flex-grow: 1;
    height: 100%;
    min-height: calc(100vh - 64px);
  }
}
